import client from "@/api/client"
import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import PasscodeInput from "@/components/Setting/Security/PasscodeInput"
import TextCopy from "@/components/TextCopy"
import { useToast } from "@/hooks"
import { auth } from "@/lib/firebaseClient"
import { useSignInStore } from "@/stores"
import { useMutation } from "@tanstack/react-query"
import { signInWithCustomToken } from "firebase/auth"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { useCallback, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import useReferralUser from "../SignInModalV2/useReferralUser"
import { useAccount, useSignMessage } from "wagmi"
import { isEmailAddress } from "@/utils/is-email-address"

type LinkAccountWalletType = {
  walletAddress: string
  email: string
  code: string
}

const LinkAccountEvm = ({ onChangeMode, isSignUp }: { onChangeMode: () => void; isSignUp?: boolean }) => {
  const [mode, setMode] = useState<"wallet" | "verify" | "final">("wallet")
  const router = useCustomRouter()
  const { setSignInModal, signInModal } = useSignInStore()
  const toast = useToast()
  const { address, connector, isConnected } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const { handleSignInSuccess, isPendingSignIn } = useReferralUser(() => setMode("final"))

  const form = useForm<LinkAccountWalletType>({
    mode: "onSubmit",
  })

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    setError,
  } = form
  const { code, email } = useWatch({
    control: form.control,
  })

  const { mutate: mutateSendLinkWalletEmail, isPending: isSending } = useMutation({
    mutationFn: (email: string) =>
      client.api.authControllerSendLinkWalletEmail({
        email,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Success",
        message: ["Email sent"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: ["Sending email confirmation failed"],
      })
    },
  })

  const { mutateAsync: mutateVerifyLinkWalletCode, isPending: isVerify } = useMutation({
    mutationFn: (data: LinkAccountWalletType) =>
      client.api.authControllerVerifyLinkWallet({
        email: data.email,
        code: data.code,
      }),
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message],
      })
    },
  })

  const handleResendLinkWalletCode = async () => {
    try {
      const { email } = getValues()

      if (!email) {
        toast({
          status: "error",
          title: "Error",
          message: ["Email is required"],
        })

        return
      }

      mutateSendLinkWalletEmail(email)
    } catch (error) {
      console.error("Error sending email confirmation", error)
    }
  }

  const handleSignInWithSolana = useCallback(
    async (email?: string, code?: string) => {
      if (!isConnected || !address) return

      try {
        const authMsg = await client.api
          .evmAuthControllerGenerateAuthMessage({
            origin: window.location.origin,
            publicKey: address,
          })
          .then(res => res.data)
        const signature = await signMessageAsync({ account: address, message: authMsg.message })
        const validated = await client.api
          .evmAuthControllerValidateAuthSignature({
            nonce: authMsg.nonce,
            signature: signature,
            origin: window.location.origin,
            publicKey: address,
            provider: "Ethereum",
            email,
            code,
          })
          .then(res => res.data)

        const credentials = await signInWithCustomToken(auth, validated.customToken)
        handleSignInSuccess({ credentials, provider: "evm" })
      } catch (err) {
        console.error("Error signing in with Solana", err)
      } finally {
      }
    },
    [isConnected, address, signMessageAsync, handleSignInSuccess, connector?.name],
  )

  const triggerInWithSolana = async (email?: string, code?: string) => {
    return handleSignInWithSolana(email, code)
  }

  const renderStep = () => {
    switch (mode) {
      case "wallet":
        return (
          <div>
            <p className="text-xs text-atherGray-500 mb-4">
              This Ethereum wallet has not been linked to your GAIA account yet!
            </p>
            <Input
              {...form.register("walletAddress")}
              className="w-full rounded-lg bg-atherGray-850"
              placeholder="Wallet address"
              disabled
              value={address ?? ""}
              rightIcon={<TextCopy value={address ?? ""} />}
            />
          </div>
        )
      case "verify":
        return (
          <div className="space-y-4">
            <Input {...register("email")} placeholder="Email address" className="placeholder:text-atherGray-500" />
            <div className="min-h-[64px]">
              <p className="text-xs text-atherGray-500">Enter the 6-digit code we sent to your email</p>
              <div className="flex flex-col h-full md:flex-row items-center">
                <PasscodeInput
                  value={code}
                  onChange={v => setValue("code", v)}
                  className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
                />
                <div className="flex items-center justify-center h-10 w-[7rem] mt-4 md:mt-0 md:ml-4 cursor-pointer">
                  <IconButton
                    isLoading={isSending}
                    colorScheme="transparent"
                    className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                    onClick={handleResendLinkWalletCode}
                  >
                    Send Code
                  </IconButton>
                </div>
              </div>
              {errors.code && <p className="text-[0.65rem] text-red-500 mt-1 leading-4">{errors.code.message}</p>}
            </div>
          </div>
        )

      default:
        return (
          <div>
            <p>
              Ethereum Wallet is linked to your GAIA account
              <br />
              Stay tuned for exclusive updates & rewards!
            </p>
          </div>
        )
    }
  }

  const submit = async e => {
    e.stopPropagation()
    e.preventDefault()
    form.clearErrors()

    const data = getValues()
    const trigger = await form.trigger()

    let isValidate = true

    if (mode === "wallet") {
      if (!address) {
        return
      }

      setMode("verify")
      return
    }

    if (mode === "verify") {
      const { email, code } = data

      //regex email
      if (!isEmailAddress(data.email)) {
        setError("email", {
          message: "Invalid email address",
        })
        isValidate = false
      }

      if (!isValidate || !trigger) return

      const verifyResult = await mutateVerifyLinkWalletCode(data)
      const { data: verifiedData } = verifyResult

      if (verifiedData?.success) {
        // link wallet to account
        triggerInWithSolana(email, code)
      }

      return
    }

    if (mode === "final") {
      setSignInModal({
        signIn: false,
        signUp: false,
      })
      return
    }
  }

  const isDisabled = mode === "wallet" ? !address : mode === "verify" ? !email || !code || code?.length < 6 : false

  return (
    <motion.div
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className="flex flex-col flex-1"
    >
      <h3 className="text-xl text-center font-semibold mb-6">
        {mode === "wallet" ? "Mostly done" : mode === "verify" ? "Link account" : "Account linked!"}
      </h3>
      {renderStep()}

      <div className="mt-8">
        <IconButton
          onClick={submit}
          disabled={isDisabled}
          isLoading={isVerify || isPendingSignIn}
          className="w-full py-3 rounded-xl"
        >
          {mode !== "wallet" ? "Complete" : "Link account"}
        </IconButton>
      </div>
      <div className="flex-1 flex flex-col justify-end">
        <hr className="my-4 border-atherGray-800" />
        <div className="text-center">
          <button
            type="button"
            className="text-atherGray-500 text-xs"
            onClick={() => {
              onChangeMode()
              if (router.query.signIn === "true" || router.query.signUp === "true") {
                router.replace(
                  {
                    query: {
                      next: router.query.next,
                      signUp: true,
                    },
                  },
                  undefined,
                  {
                    shallow: true,
                  },
                )
                return
              }

              if (isSignUp) {
                setSignInModal({
                  signUp: true,
                })
              } else {
                setSignInModal({
                  signIn: true,
                })
              }
            }}
          >
            Go back
          </button>
        </div>
      </div>
    </motion.div>
  )
}

export default LinkAccountEvm
