import client from "@/api/client"
import ClientOnly from "@/components/ClientOnly"
import IconButton from "@/components/IconButton"
import { cdnPublicFolderUrl, clientDomain, telegramBotId } from "@/constants"
import { useToast } from "@/hooks"
import { auth } from "@/lib/firebaseClient"
import { isFacebookApp } from "@/utils"
import { cn } from "@/utils/cn"
import { css } from "@emotion/css"
import { SignInButton, StatusAPIResponse } from "@farcaster/auth-kit"
import { useWalletMultiButton } from "@solana/wallet-adapter-base-ui"
import { useWallet } from "@solana/wallet-adapter-react"
import { useWalletModal } from "@solana/wallet-adapter-react-ui"
import { useWalletInfo, useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react"
import base58 from "bs58"
import { GoogleAuthProvider, signInWithCustomToken, signInWithPopup } from "firebase/auth"
import Image from "next/image"
import Link from "next/link"
import { useCallback, useEffect, useState } from "react"
import { useAccount, useDisconnect, useSignMessage } from "wagmi"
import useReferralUser from "./useReferralUser"
import { TelegramAuthData } from "@telegram-auth/react"
import useScript from "@/hooks/useScript"

const SignInWithThirdParty = ({
  onClose,
  isSignUp,
  onChangeLinkWallet,
  onChangeLinkFarcaster,
}: {
  onClose: () => void
  isSignUp?: boolean
  onChangeLinkWallet?: (mode?: "link-wallet" | "link-wallet-evm") => void
  onChangeLinkFarcaster?: (payload: any) => void
}) => {
  const { handleSignInSuccess } = useReferralUser(onClose)
  const toast = useToast()
  const [isAboutToSignInWithWalletEvm, setAboutToSignInWithWalletEvm] = useState(false)
  const [isSigningInWithWalletEvm, setSigningInWithWalletEvm] = useState(false)

  const [isAboutToSignInWithWallet, setAboutToSignInWithWallet] = useState(false)
  const [isSigningInWithWallet, setSigningInWithWallet] = useState(false)
  const [isSignature, setIsSignature] = useState(false)

  const [isSignInSocial, setIsSignInSocial] = useState(false)
  const [isSigningWithFarcaster, setIsSigningWithFarcaster] = useState(false)
  const [isSignInWithTelegram, setIsSignInWithTelegram] = useState(false)
  const { open } = useWeb3Modal()
  const { address, connector, isConnected, isConnecting, isDisconnected, isReconnecting, status } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const { disconnectAsync } = useDisconnect()
  const { walletInfo } = useWalletInfo()
  const { open: openWalletModal } = useWeb3ModalState()

  const wallet = useWallet()
  const walletButton = useWalletMultiButton({
    onSelectWallet: config => console.log("onSelectWallet", config),
  })
  const walletModal = useWalletModal()
  useScript("https://telegram.org/js/telegram-widget.js")

  const signInWithGoogle = async () => {
    const isFacebook = isFacebookApp()

    if (isFacebook) {
      toast({
        status: "error",
        title: "Can not log in from Facebook!",
        message: ["Please open this link in the external browser to sign in with Google account"],
      })
      return
    }

    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ prompt: "select_account" })

    setIsSignInSocial(true)

    const credentials = await signInWithPopup(auth, provider).catch(e => {
      const errorMessage =
        e.code === "auth/popup-closed-by-user" || e.code === "auth/cancelled-popup-request"
          ? "Popup closed by user"
          : "Something went wrong" // TODO: handle other error codes here

      toast({
        status: "error",
        title: "Error signing in with Google",
        message: [errorMessage],
      })
      setIsSignInSocial(false)
    })

    if (credentials) {
      handleSignInSuccess({ credentials, provider: "google" })
    }
  }

  const handleSignInWithEvm = useCallback(async () => {
    if (!isConnected || !address || !signMessageAsync) return

    setSigningInWithWalletEvm(true)

    try {
      const connectedUser = await client.api.walletControllerGetConnectedUser(address).then(res => res.data)
      const { uid } = connectedUser

      if (!uid) {
        onChangeLinkWallet?.("link-wallet-evm")
        return
      }

      const authMsg = await client.api
        .evmAuthControllerGenerateAuthMessage({
          origin: window.location.origin,
          publicKey: address,
        })
        .then(res => res.data)

      const signature = await signMessageAsync({ account: address, message: authMsg.message })

      if (!signature) return

      const validated = await client.api
        .evmAuthControllerValidateAuthSignature({
          nonce: authMsg.nonce,
          signature,
          origin: window.location.origin,
          publicKey: address,
        })
        .then(res => res.data)
      const credentials = await signInWithCustomToken(auth, validated.customToken)
      handleSignInSuccess({ credentials, provider: "evm" })
    } catch (err: any) {
      console.error(`Error signing in with wallet`, err)
    } finally {
      setAboutToSignInWithWalletEvm(false)
      setSigningInWithWalletEvm(false)
    }
  }, [isConnected, address, signMessageAsync, onChangeLinkWallet, handleSignInSuccess])

  const triggerInWithEvm = async () => {
    if (!isConnected) {
      await open({ view: "Connect" })
    }

    if (!address) {
      return setAboutToSignInWithWalletEvm(true)
    }

    return handleSignInWithEvm()
  }

  const handleSwitchWalletEvm = async () => {
    if (isConnected) {
      await disconnectAsync()
    }
    await open({ view: "Connect" })
    setAboutToSignInWithWalletEvm(true)
  }

  const handleSignInWithSolana = useCallback(
    async (email?: string, code?: string) => {
      if (!wallet.connected || !wallet.publicKey || !wallet.signMessage) return

      setSigningInWithWallet(true)

      try {
        const connectedUser = await client.api
          .walletControllerGetConnectedUser(wallet.publicKey.toString())
          .then(res => res.data)
        const { uid } = connectedUser

        if (!uid) {
          onChangeLinkWallet?.("link-wallet")
          return
        }

        const authMsg = await client.api
          .solanaAuthControllerGenerateAuthMessage({
            origin: window.location.origin,
            publicKey: wallet.publicKey.toString(),
          })
          .then(res => res.data)

        const data = new TextEncoder().encode(authMsg.message)
        const signature = await wallet.signMessage(data)

        if (!signature) return

        setIsSignature(true)

        const serializedSignature = base58.encode(signature)

        const validated = await client.api
          .solanaAuthControllerValidateAuthSignature({
            nonce: authMsg.nonce,
            signature: serializedSignature,
            origin: window.location.origin,
            publicKey: wallet.publicKey.toString(),
            email,
            code,
          })
          .then(res => res.data)

        const credentials = await signInWithCustomToken(auth, validated.customToken)
        handleSignInSuccess({ credentials, provider: "solana" })
      } catch (err: any) {
        console.error("Error signing in with Solana", err)
      } finally {
        setAboutToSignInWithWallet(false)
        setSigningInWithWallet(false)
      }
    },
    [wallet, handleSignInSuccess, onChangeLinkWallet],
  )

  const triggerInWithSolana = async (email?: string, code?: string) => {
    if (!wallet.connected) {
      walletModal.setVisible(true)
    }

    if (!wallet.publicKey) {
      return setAboutToSignInWithWallet(true)
    }

    return handleSignInWithSolana(email, code)
  }

  const handleSwitchWallet = async () => {
    walletModal.setVisible(true)
    if (wallet.connected) {
      await wallet.disconnect()
    }
    return setAboutToSignInWithWallet(true)
  }

  const handleSuccessFarcaster = async (res: StatusAPIResponse) => {
    setIsSigningWithFarcaster(true)

    try {
      const { fid, nonce, signature, message, displayName, username, pfpUrl } = res

      if (!fid) {
        toast({
          status: "error",
          title: "Error signing in with Farcaster",
          message: ["Farcaster Id not found"],
        })
        return
      }

      const connectedUser = await client.api.farcasterAuthControllerGetConnectedUser(fid).then(res => res.data)
      const { uid } = connectedUser

      if (!uid) {
        const payload = {
          domain: clientDomain,
          nonce: nonce,
          signature: signature || "",
          message: message || "",
          farcasterId: fid,
          displayName: displayName,
          username: username,
          pfpUrl: pfpUrl,
        }
        onChangeLinkFarcaster?.(payload)
        return
      }

      const validated = await client.api
        .farcasterAuthControllerAuthorize({
          domain: clientDomain,
          nonce: nonce,
          signature: signature || "",
          message: message || "",
          farcasterId: fid,
          displayName: displayName,
          username: username,
          pfpUrl: pfpUrl,
        })
        .then(res => res.data)
      const credentials = await signInWithCustomToken(auth, validated.customToken)
      handleSignInSuccess({ credentials, provider: "farcaster" })
    } catch (err: any) {
      console.error(`Error signing in with Farcaster`, err)
    } finally {
      setIsSigningWithFarcaster(false)
    }
  }

  const handleErrorFarcaster = () => {
    toast({
      status: "error",
      title: "Error signing in with Farcaster",
      message: ["Unable to sign in at this time."],
    })
  }

  const handleSignInWithTelegramClick = () => {
    if (!window.Telegram?.Login?.auth) {
      toast({
        status: "error",
        title: "Error signing in with Telegram",
        message: ["Telegram Auth not available. Please try again later."],
      })
      return
    }

    window.Telegram.Login.auth({ bot_id: telegramBotId, request_access: "write" }, data => {
      if (!data) {
        // authorization failed
        toast({
          status: "error",
          title: "Error signing in with Telegram",
          message: ["Authorization failed"],
        })
      }

      // console.log("Telegram Auth Data", data)
      handleSignInWithTelegram(data)
    })
  }

  const handleSignInWithTelegram = async (data: TelegramAuthData) => {
    setIsSignInWithTelegram(true)
    // console.log("Telegram Auth Data", data)

    try {
      const res = await client.api.telegramAuthControllerAuthorize(data)
      const { customToken } = res.data
      const credentials = await signInWithCustomToken(auth, customToken)
      handleSignInSuccess({ credentials, provider: "telegram" })
    } catch (err: any) {
      console.error(`Error signing in with Telegram`, err)
    } finally {
      setIsSignInWithTelegram(false)
    }
  }

  // evm
  useEffect(() => {
    if (!openWalletModal && isAboutToSignInWithWalletEvm && !isConnecting) {
      setAboutToSignInWithWalletEvm(false)
      document.body.style.overflow = "unset"
    }
  }, [isConnecting, openWalletModal, isAboutToSignInWithWalletEvm])

  useEffect(() => {
    if (isConnected && address && isAboutToSignInWithWalletEvm) {
      setAboutToSignInWithWalletEvm(false)
      handleSignInWithEvm()
    }
  }, [isConnected, address, isAboutToSignInWithWalletEvm, handleSignInWithEvm])
  // evm

  // solana
  useEffect(() => {
    if (!walletModal.visible && isAboutToSignInWithWallet && !wallet.connecting) {
      setAboutToSignInWithWallet(false)
      setIsSignature(false)
      document.body.style.overflow = "unset"
    }
  }, [wallet.connecting, walletModal.visible, isAboutToSignInWithWallet])

  useEffect(() => {
    if (wallet.connected && wallet.publicKey && isAboutToSignInWithWallet) {
      handleSignInWithSolana()
    }
  }, [wallet.connected, wallet.publicKey, isAboutToSignInWithWallet, handleSignInWithSolana])
  // solana

  return (
    <div className="flex flex-col items-center">
      <p className="text-atherGray-500 text-center mb-6">or</p>
      <div className={cn("w-full grid md:max-w-[22rem] grid-cols-5")}>
        <div className="flex flex-col items-center">
          <IconButton
            isLoading={isSignInSocial}
            onClick={() => signInWithGoogle()}
            colorScheme="secondary"
            className={cn("rounded-xl bg-atherGray-850 w-[3rem] h-[3rem] justify-center flex-col")}
          >
            <img src={`${cdnPublicFolderUrl}/google.png`} alt="Google Logo" width={32} height={32} />
          </IconButton>
          <p className="line-clamp-1 mt-1 text-[0.65rem] md:text-xs text-center">Google</p>
        </div>
        <ClientOnly>
          <div className="flex flex-col items-center overflow-hidden">
            <IconButton
              onClick={() => triggerInWithSolana()}
              colorScheme="secondary"
              className={cn("py-3 rounded-xl bg-atherGray-850 w-[3rem] h-[3rem] justify-center flex-col")}
              isLoading={isAboutToSignInWithWallet || isSigningInWithWallet}
            >
              <Image
                src={walletButton.walletIcon || `${cdnPublicFolderUrl}/solana.svg`}
                alt="Wallet Logo"
                width={32}
                height={32}
              />
            </IconButton>
            <p className="line-clamp-1 mt-1 text-[0.65rem] md:text-xs text-center">
              {walletButton.walletName || "Solana"}
            </p>
            {walletButton.walletName && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
              <p
                className="text-xs mt-1 text-center text-atherGray-300 underline cursor-pointer"
                onClick={handleSwitchWallet}
              >
                switch
              </p>
            )}
          </div>
        </ClientOnly>
        <ClientOnly>
          <div className="flex flex-col justify-center items-center overflow-hidden">
            <IconButton
              onClick={() => triggerInWithEvm()}
              colorScheme="secondary"
              className={cn("py-3 rounded-xl bg-atherGray-850 w-[3rem] h-[3rem] justify-center flex-col")}
              isLoading={isAboutToSignInWithWalletEvm || isSigningInWithWalletEvm}
            >
              <img
                src={walletInfo?.icon || `${cdnPublicFolderUrl}/eth-diamond-purple.png`}
                alt="Wallet Logo"
                className="w-8 h-8"
              />
            </IconButton>
            <p className="line-clamp-1 mt-1 text-[0.65rem] md:text-xs text-center">{walletInfo?.name || "Ethereum"}</p>
            {walletInfo?.name && !isAboutToSignInWithWalletEvm && !isSigningInWithWalletEvm && (
              <p
                className="text-xs mt-1 text-center text-atherGray-300 underline cursor-pointer"
                onClick={handleSwitchWalletEvm}
              >
                switch
              </p>
            )}
          </div>
        </ClientOnly>
        <ClientOnly>
          <div
            className={css({
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              ".fc-authkit-signin-button": {
                width: "100%",
                height: "100%",
                span: {
                  display: "none",
                },
                button: {
                  width: "100%",
                  height: "100%",
                  padding: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              },
            })}
          >
            {isSigningWithFarcaster ? (
              <IconButton
                colorScheme="secondary"
                className={cn("py-3 rounded-xl bg-atherGray-850 w-[3rem] h-[3rem] justify-center flex-col")}
                isLoading
              />
            ) : (
              <div className="w-[3rem] h-[3rem]">
                <SignInButton onSuccess={handleSuccessFarcaster} hideSignOut onError={handleErrorFarcaster} />
              </div>
            )}
            <p className="line-clamp-1 mt-1 text-[0.65rem] md:text-xs text-center">Farcaster</p>
          </div>
        </ClientOnly>
        <div className="flex flex-col items-center">
          <IconButton
            isLoading={isSignInWithTelegram}
            onClick={() => handleSignInWithTelegramClick()}
            colorScheme="telegram"
            className={cn("rounded-xl w-[3rem] h-[3rem] justify-center flex-col")}
          >
            <img src={`${cdnPublicFolderUrl}/telegram-icon-100x85.png`} alt="Google Logo" width={32} height={32} />
          </IconButton>
          <p className="line-clamp-1 mt-1 text-[0.65rem] md:text-xs text-center">Telegram</p>
        </div>
      </div>
      <p className="mt-4 text-[0.65rem] leading-4 text-atherGray-500 text-center">
        This site is protected by reCAPTCHA and the Google{" "}
        <Link className="font-semidbold text-[#D9D9D9]" target="_blank" href="https://policies.google.com/privacy">
          Privacy Policy
        </Link>{" "}
        and{" "}
        <Link className="font-semidbold text-[#D9D9D9]" target="_blank" href="https://policies.google.com/terms">
          Terms of Service
        </Link>{" "}
        apply.
      </p>
    </div>
  )
}

export default SignInWithThirdParty
